/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pflMb1ep .lpcBA25b {
  background-color: var(--primary-color-bg);
}
.pflMb1ep .lpcBA25b .z12Ociyx {
  font-family: var(--font-family);
  font-weight: 600;
  text-align: center;
  color: var(--primary-color-black);
}
.pflMb1ep .lpcBA25b .nk6HjUlq {
  margin-top: 1rem;
}
.pflMb1ep .lpcBA25b .QmyZYYLW {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  max-width: 1200px;
}
.pflMb1ep .lpcBA25b .QmyZYYLW .zmXiadLL {
  background-color: var(--primary-color-blue);
  color: var(--primary-color-white);
  height: 24px;
  width: 5.75rem;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  transition: all 0.3s;
  align-items: center;
  font-size: 12px;
}
.pflMb1ep .lpcBA25b .QmyZYYLW .zmXiadLL:hover {
  opacity: 0.8;
}
.pflMb1ep .lpcBA25b .ZwATxPzW {
  margin-right: 0 !important;
}
.pflMb1ep .lpcBA25b .SUdhoHe7 {
  border-radius: 8px;
  background-color: var(--primary-color-white);
}
.pflMb1ep .lpcBA25b .SUdhoHe7 .EfxWMoE7 {
  border-radius: 8px;
  width: 100%;
}
.pflMb1ep .lpcBA25b .UfM6asdt {
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 600;
  text-align: left;
  color: var(--primary-color-black);
}
@media (min-width: 1200px) {
  .pflMb1ep {
    padding: 3rem 0;
  }
  .pflMb1ep .lpcBA25b {
    margin: 0 auto;
    width: 75rem;
  }
  .pflMb1ep .lpcBA25b .z12Ociyx {
    font-size: 28px;
  }
  .pflMb1ep .lpcBA25b .SUdhoHe7 {
    margin-right: 1.25rem;
    display: block;
    transition: all 0.3s;
  }
  .pflMb1ep .lpcBA25b .SUdhoHe7:hover {
    box-shadow: 0px 4px 12px 0px rgba(4, 43, 79, 0.08);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  .pflMb1ep .lpcBA25b .SUdhoHe7 .EfxWMoE7 {
    height: 160px;
  }
  .pflMb1ep .lpcBA25b .RTfmE71i {
    padding: 1rem;
  }
  .pflMb1ep .lpcBA25b .UfM6asdt {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
@media (max-width: 1199px) and (min-width: 0px) {
  .pflMb1ep .lpcBA25b {
    padding: 1.5rem var(--mobile-all-margin);
    padding-bottom: 0.5rem;
  }
  .pflMb1ep .lpcBA25b .z12Ociyx {
    font-size: 20px;
  }
  .pflMb1ep .lpcBA25b .SUdhoHe7 {
    height: 100px;
    display: flex;
    align-items: center;
  }
  .pflMb1ep .lpcBA25b .SUdhoHe7 .EfxWMoE7 {
    width: 8.125rem;
    height: 100%;
  }
  .pflMb1ep .lpcBA25b .RTfmE71i {
    padding: 1.625rem 0.75rem;
  }
}
@media (max-width: 1199px) and (min-width: 500px) {
  .pflMb1ep .lpcBA25b .SUdhoHe7 {
    margin-right: 1.25rem;
    margin-bottom: 20px;
  }
}
@media (max-width: 499px) and (min-width: 0px) {
  .pflMb1ep .lpcBA25b .SUdhoHe7 {
    margin-bottom: 16px;
  }
}

